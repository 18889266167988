import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { graphql, Link, withPrefix } from "gatsby"

import Image from "../components/Image"
import Layout from "../components/Layout"

import "../css/blog.scss"

type HeadlineProps = {
  title: string
}

function TyperHeadline({ title }: HeadlineProps) {
  const [text, setText] = useState("")

  useEffect(() => {
    const timeout = setTimeout(() => {
      setText(title.slice(0, text.length + 10))
    }, 200)
    return () => clearTimeout(timeout)
  }, [text, title])

  return (
    <>
      <style type="text/css">
        {`
          .typer-container span:after {
          content: "${text.slice(text.length - 10)}";
        }
      `}
      </style>
      <div className="typer-container">
        <span>{text}</span>
      </div>
    </>
  )
}

type Post = {
  id: string
  frontmatter: {
    path?: string
    title: string
    subtitle: string
    postcategory?: string
    headerImage?: {
      image: {
        relativePath: string
      }
    }
    date: string
  }
  fields: {
    slug: string
  }
}

function getPostLink(post: Post) {
  return post.frontmatter.path || post.fields.slug
}

type BitcoinfiPageProps = {
  data: {
    allMarkdownRemark: {
      nodes: Post[]
    }
  }
}

function BitcoinfiPage({ data }: BitcoinfiPageProps) {
  const filteredPosts = data.allMarkdownRemark.nodes.filter(
    (post) => post.frontmatter.postcategory === "bitcoinfi",
  )

  return (
  <>
    <Layout hideEmbellishment>
      <Helmet>
        <title>Thesis* - Blog</title>
      </Helmet>
      <div className="section blog-index background---black">
        <div className="xl---padding-12---1-col lg---padding-12---1-col text---white">
        <a href="https://blockspace.media/bitcoinfi/" target="_blank" className="desktop"><img src={withPrefix("/images/bitcoinfi-podcast-banner.png")} style={{marginTop: "60px"}} /></a>
        <a href="https://blockspace.media/bitcoinfi/" target="_blank" className="mobile"><img src={withPrefix("/images/bitcoinfi-podcast-mobile.png")} style={{marginTop: "60px"}} /></a>
          {(filteredPosts as Post[]).map((post, index) => (
            <article className="blog-article" key={post.id}>
              {index === 0 ? (
                <div className="blog-first xl---padding-24---1-col lg---padding-24---1-col">
                  <h1 className="text---white typer">
                    <Link to={`${post.fields.slug}`} className="text---white">
                      <TyperHeadline title={post.frontmatter.subtitle} />
                    </Link>
                  </h1>
                  <p className="byline">
                    <Link to={post.fields.slug} className="text---white">
                      {post.frontmatter.title}
                    </Link>
                  </p>
                  <div className="down-arrow">
                    <Link to="#other-content">
                      <img
                        src={withPrefix("/images/down-arrow.png")}
                        alt="down arrow"
                      />
                    </Link>
                  </div>

                  <Link to={getPostLink(post)} className="cta">
                    Read More
                  </Link>
                  {post.frontmatter.headerImage && (
                    <Image
                      className="header-image"
                      imageData={post.frontmatter.headerImage}
                    />
                  )}
                </div>
              ) : (
                <>
                  <div className="blog-title" id="other-content">
                    <p className="byline">{post.frontmatter.date}</p>
                    <h1 className="text---white">
                      <Link to={getPostLink(post)} className="text---white">
                        {post.frontmatter.title}
                      </Link>
                    </h1>
                  </div>
                  {post.frontmatter.headerImage && post.frontmatter.headerImage.image && (
                    <div
                      className="blog-image"
                      style={{
                        backgroundImage: `url(${withPrefix(
                          `/images/${post.frontmatter.headerImage.image.relativePath}`
                        )})`,
                      }}
                    />
                  )}
                </>
              )}
            </article>
          ))}
        </div>
      </div>
    </Layout>
  )
  </>
  )
}

export const query = graphql`
  query postListQuery {
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "blog-post" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          path
          title
          subtitle
          richTitle {
            html
          }
          postcategory
          date(formatString: "MMMM DD, YYYY")
          byline
          headerImage {
            image {
              relativePath
            }
            alt
          }
        }
      }
    }
  }
`

export default BitcoinfiPage